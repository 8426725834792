import React, { useEffect , useState } from 'react'
import MainLayout from '../../components/Layouts/MainLayout'
import Herocomp from '../../components/Publicaciones/herocomp'
import TopBar from '../../components/Publicaciones/TopBar'
import useContentful from '../../utils/useContentful'
import "./articulos/articulos.css"
import { Helmet } from 'react-helmet'



const Cardcomponent = ({image, title, author,text, linkpoint}) => {
  return(
    <div className="card">
      <a href={linkpoint} target="_blank" ><img className="image" src={image}/></a>
      <a href={linkpoint} target="_blank"><h1 className="title">{title}</h1></a>
      <h2 className="author">{author}</h2>
      <a href={linkpoint} target="_blank" style={{color: "black"}}><p className="text">{text}</p></a>
      <a href={linkpoint} target="_blank"><img className="arrow" src="/buttonbig.png"/></a>
    </div>
  )
}




const ArticuloEntryId = "1cz49tBW52Ik47wuHuBA4i";

const Index =(props) => {
const [data , setData] = useState()
  const { data: articulo, fetched } = useContentful({ id: ArticuloEntryId});

 

useEffect(()=>{

  const contentdata = [];
  const lala = articulo?.fields.articulos.map((item,index) => {

      return(
        {
          image: item.fields.cardImage.fields.file.url,
          title: item.fields.cardTitle,
          author: item.fields.author,
          text:item.fields.cardDescription ,
          linkpoint: "https://www.instagram.com/p/CofjiOVN07E/?utm_source=ig_web_copy_link" ,
        }
      )
  })

  setData(lala);
 
}

, [articulo])




  return(

    <>
    <Helmet>
    <link
              rel="alternate"
              hreflang="es-pe"
              href="https://www.datascienceglobalskills.pe/publicaciones/articulos"
            />
            <link rel="canonical" href="https://www.datascienceglobalskills.pe/publicaciones/articulos" />

    </Helmet>

    {fetched && data? <MainLayout active={5}>
      <Herocomp imageinput={articulo.fields.hero.fields.file.url}></Herocomp>
      <TopBar item={1}></TopBar>

    <div className="art2Container">
    <div className="art3Container">
      {data.map((item) => {
        return (
          <Cardcomponent image={item.image} title={item.title} author={item.author} text={item.text} linkpoint={item.linkpoint}></Cardcomponent>
        )
      }
      )}
    </div>
    </div>

    </MainLayout>:
     <div
     style={{
       width: "100vw",
       height: "100vh",
       display: "flex",
       justifyContent: "center",
       marginTop: "0px",
       padding: "15%",
     }}
   >
     {" "}
     <svg
       version="1.1"
       id="L9"
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 100 100"
       enableBlackground="new 0 0 0 0"
       xmlSpace="preserve"
     >
       <path
         fill="#008991"
         d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
       >
         <animateTransform
           attributeName="transform"
           attributeType="XML"
           type="rotate"
           dur="1s"
           from="0 50 50"
           to="360 50 50"
           repeatCount="indefinite"
         />
       </path>
     </svg>
   </div>
}
   </>
  );
}

export default Index;